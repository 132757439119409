<template>
  <section class="static-wrap" v-if="showWrap">
    <div class="content">
      <div class="title">{{dataTicket.actName}}</div>
      <div class="list">
        <div class="item">
          <div class="item-title">参与人数</div>
          <div class="item-content">{{dataTicket.totalPass}}</div>
        </div>
        <div class="item">
          <div class="item-title">注册人数</div>
          <div class="item-content">{{dataTicket.registerNum}}</div>
        </div>
        <div class="item">
          <div class="item-title">app下载数</div>
          <div class="item-content">{{dataTicket.appDownloadNum}}</div>
        </div>
        <div class="item">
          <div class="item-title">登录用户人数</div>
          <div class="item-content">{{dataTicket.loginUserNum}}</div>
        </div>
        <div class="item boder-bottom-none">
          <div class="item-title">首单完成人数</div>
          <div class="item-content">{{dataTicket.firstOrderNum}}</div>
        </div>
        <div class="item boder-bottom-none">
          <div class="item-title">首单转化率</div>
          <div class="item-content">{{transFromRate(dataTicket.firstOrderFinishRate)}}%</div>
        </div>
      </div>
    </div>
    <div class="detail">
      <van-button plain type="primary" class="btn" @click="handleGoDetail">查看用户明细</van-button>
    </div>
  </section>
  <section class="no-data" v-else>
    <img src="@/assets/images/none@2x.png">
    <p class="title">已暂停查看数据</p>
  </section>
</template>

<script>
import { Toast } from "vant";
import { getStaticInfo } from "@/api/coupon";
import { transFromRate } from "@/filters/index";
export default {
  components: {},
  data() {
    return {
      showWrap: true,
      ticketUuid: "",
      dataTicket: {},
      transFromRate
    };
  },
  mounted() {
    this.$store.commit("loading/HIDELOADING");
    this.ticketUuid = this.$route.query.ticketUuid
    this.getStaticInfo()
  },
  methods: {
    getStaticInfo(){
      getStaticInfo({ uuid: this.ticketUuid }).then((res) => {
        if (res.success) {
          this.dataTicket = res.data;
        }else{
          this.showWrap = false
          // Toast(res.msg);
        }
      });
    },
    handleGoDetail() {
      this.$router.push({
        path: '/coupon-statics-detail',
        query: {
          ticketUuid: this.ticketUuid
        }
      })
    }
  },
};
</script>

<style scoped lang="scss">
.static-wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  font-family: PingFangSC-Regular, PingFang SC;
  background: linear-gradient(180deg, rgba(255, 236, 0, .8) 0%, rgba(255,255,255,0) 170px);
  .content {
    display: flex;
    flex-direction: column;
    margin: 12px;
    background: #FFFFFF;
    box-shadow: 0px 4px 10px 0px rgba(217,217,229,0.42);
    border-radius: 8px;
    box-sizing: border-box;
    .title{
      width: 100%;
      height: 52px;
      line-height: 52px;
      background: rgba(255,236,0,0.1);
      border-radius: 8px 8px 0px 0px;
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      box-sizing: border-box;
    }
    .list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
    .item {
      width: 50%;
      padding: 22px 24px;
      border-right: 0.5px solid rgba(0,0,0,0.1);
      border-bottom: 0.5px solid rgba(0,0,0,0.1);
      box-sizing: border-box;
      text-align: left;
      &:nth-child(2n) {
        border-right: none;
      }
      &.boder-bottom-none {
        border-bottom: none;
      }
      .item-title {
        font-size: 13px;
        font-weight: 400;
        color: #999999;
        line-height: 18px;
      }
      .item-content{
        font-size: 20px;
        font-weight: 600;
        color: #222220;
        line-height: 24px;
        margin-top: 6px;
      }
    }
  }
  .detail {
    margin-top: 12px;
    .btn {
      border-radius: 6px;
      border: 1px solid rgba(0,0,0,0.16);
      background: none;
      font-size: 14px;
      font-weight: 500;
      color: #999999;
      line-height: 16px;
    }
  }
}
.no-data {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  img{
    width: 203px;
    height: 130px;
    margin: 0 auto 23px;
  }
  .title{
    font-size: 15px;
    font-weight: 400;
    color: #999999;
    line-height: 21px;
  }
}
</style>

